<template>
  <div>
    <!-- Footer -->
    <div class="webCustomFooter" v-if="this.footerData.is">
      <div
        class="row customRaffleFooterBg m-0"
        :style="{
          background: `${themeData.primaryColor}`,
          padding: '40px 0px 0px',
        }"
      >
        <div
          class="row customRaffleFooterBg m-0"
          :style="{
            background: `${themeData.primaryColor}`,
            padding: '40px 0px',
          }"
        >
          <div class="col-1"></div>
          <div
            class="col-3"
            v-if="footerData?.inSupportOf?.is || footerData?.socials?.is"
            style="display: flex; flex-direction: column; align-items: center"
          >
            <div
              class="inSupportOfmainDivWebFooter"
              v-if="footerData?.inSupportOf?.is"
            >
              <div class="inSupportOf">
                <h2
                  class="customSupportTxt footerInSupportOf"
                  v-if="footerData?.inSupportOf?.is"
                  :style="{
                    color: this?.themeData?.heroInSupportOf?.fontColor
                      ? this.themeData.heroInSupportOf.fontColor + '!important'
                      : '#ffffff' + '!important',
                  }"
                >
                  IN SUPPORT OF
                </h2>
                <h2
                  class="customfooterSupportTxt heroDescription"
                  v-if="footerData?.inSupportOf?.value !== ''"
                  :style="{
                    color: this?.themeData?.heroInSupportOf?.fontColor
                      ? this.themeData.heroInSupportOf.fontColor + '!important'
                      : '#ffffff' + '!important',
                  }"
                >
                  {{ footerContent?.inSupportOf?.value }}
                </h2>
                <div
                  class="d-flex justify-content-center"
                  v-if="footerData?.inSupportOf?.is"
                >
                  <img
                    :src="fetchImage(footerContent?.inSupportOf?.img?.value)"
                    alt=""
                    class="footerCharityImg imageFitStyle"
                    :style="{
                      width: '100px',
                      height: 'auto',
                      maxWidth: '115px',
                      objectFit: 'contain',
                    }"
                  />
                </div>
              </div>
            </div>

            <h3
              class="customfooterSupportTxt customfooterFollowTxt heroTitle"
              v-if="footerData?.socials?.is && hasSocialLinks"
            >
              Follow us
            </h3>
            <div
              class="customFooterSocialMediaImgs"
              v-if="footerData?.socials?.is"
            >
              <a
                :href="this.footerContent.socials?.links.facebook.link"
                target="_blank"
                v-if="this.footerContent.socials?.links.facebook.link"
                ><img
                  src="https://static.raffolux.com/static/website/redev_images/Facebook+-+Negative.svg"
                  alt=""
                  class="imageFitStyle"
              /></a>
              <a
                :href="this.footerContent.socials?.links.instagram.link"
                target="_blank"
                v-if="this.footerContent.socials?.links.instagram.link"
                ><img
                  src="https://static.raffolux.com/static/website/redev_images/Instagram+-+Negative.svg"
                  alt=""
                  class="imageFitStyle"
              /></a>
              <a
                :href="this.footerContent.socials?.links.twitter.link"
                target="_blank"
                v-if="this.footerContent.socials?.links.twitter.link"
                ><img
                  src="https://static.raffolux.com/static/website/redev_images/Twitter+-+Negative.svg"
                  alt=""
                  class="imageFitStyle"
              /></a>
              <a
                :href="this.footerContent.socials?.links.linkedin.link"
                target="_blank"
                v-if="this.footerContent.socials?.links.linkedin.link"
                ><img
                  src="https://static.raffolux.com/static/website/redev_images/LinkedIn+-+Negative.svg"
                  alt=""
                  class="imageFitStyle"
              /></a>
            </div>
          </div>
          <div class="col-7" v-if="footerData?.is">
            <div
              class="customFooterRightSec"
              v-if="footerData?.description?.is"
              style="width: 700px"
            >
              <p
                class="customFooterRightSecTxt mb-0 footerDescription"
                :style="{
                  color:
                    (footerData?.description?.css?.fontColor || '#ba0d0d') +
                    ' !important',
                  fontFamily:
                    (themeData.heroDescription.font || 'Garibato') +
                    ' !important',
                  fontWeight:
                    (themeData.heroDescription.fontWeight || '300') +
                    ' !important',
                }"
              >
                This raffle is proudly brought to you by RaiseHero, a Raffolux
                company.
              </p>
              <br />
              <p
                class="customFooterRightSecTxt mb-0 footerDescription"
                :style="{
                  whiteSpace: 'pre-line',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  color:
                    (footerData?.description?.css?.fontColor || '#ba0d0d') +
                    ' !important',
                  fontFamily:
                    (themeData.heroDescription.font || 'Garibato') +
                    ' !important',
                  fontWeight:
                    (themeData.heroDescription.fontWeight || '300') +
                    ' !important',
                }"
              >
                {{ footerContent?.description?.value }}
              </p>
            </div>
          </div>
          <div class="col-1"></div>
        </div>
        <div
          class="customFooterRaffoluxSec logoContainerPadding"
          v-if="footerData?.logo?.is"
          :style="{ backgroundColor: footerData?.logo?.css?.bgColor }"
        >
          <img
            :src="fetchImage(footerContent?.logo?.value)"
            class="customFooterRaffoluxImg imageFitStyle"
            alt="Logo"
          />
        </div>
      </div>
    </div>
    <!-- web footer sec ends-->
    <!-- mob footer sec -->
    <div
      class="mobCustomFooter"
      v-if="!isRaffle && this.footerData.is"
      :style="{
        background: `${themeData.primaryColor}`,
        margin: isPaymentFailure
          ? '0px 0px 0px 0px !important'
          : '0px 0px 100px 0px !important',
        paddingBottom: isRafflePage ? '115px' : '25px',
      }"
    >
      <div
        class="inSupportOfmainDivWebFooter"
        v-if="footerData?.inSupportOf?.is"
      >
        <div class="inSupportOf">
          <h2
            class="customSupportTxt"
            :style="{
              color: this?.themeData?.heroInSupportOf?.fontColor
                ? this.themeData.heroInSupportOf.fontColor + '!important'
                : '#ffffff' + '!important',
            }"
          >
            IN SUPPORT OF
          </h2>
          <h2
            class="customSupportTxt"
            :style="{
              color: this?.themeData?.heroInSupportOf?.fontColor
                ? this.themeData.heroInSupportOf.fontColor + '!important'
                : '#ffffff' + '!important',
            }"
          >
            {{ footerContent?.inSupportOf?.value }}
          </h2>
          <div class="d-flex justify-content-center">
            <img
              :src="fetchImage(footerContent?.inSupportOf?.img?.value)"
              alt=""
              class="footerCharityImg imageFitStyle"
              :style="{
                width: '100px',
                height: 'auto',
                maxWidth: '115px',
                objectFit: 'contain',
              }"
            />
          </div>
        </div>
      </div>
      <h2
        :style="{ marginTop: '20px' }"
        class="heroTitle"
        v-if="footerData?.socials?.is && hasSocialLinks"
      >
        Follow us
      </h2>
      <div
        class="customFooterSocialMediaImgs"
        v-if="footerData?.socials?.is && hasSocialLinks"
      >
        <a
          :href="this.footerContent.socials?.links.facebook.link"
          target="_blank"
          v-if="this.footerContent.socials?.links.facebook.link"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/Facebook+-+Negative.svg"
            alt=""
            class="imageFitStyle"
        /></a>
        <a
          :href="this.footerContent.socials?.links.instagram.link"
          target="_blank"
          v-if="this.footerContent.socials?.links.instagram.link"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/Instagram+-+Negative.svg"
            alt=""
            class="imageFitStyle"
        /></a>
        <a
          :href="this.footerContent.socials?.links.twitter.link"
          target="_blank"
          v-if="this.footerContent.socials?.links.twitter.link"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/Twitter+-+Negative.svg"
            alt=""
            class="imageFitStyle"
        /></a>
        <a
          :href="this.footerContent.socials?.links.linkedin.link"
          target="_blank"
          v-if="this.footerContent.socials?.links.linkedin.link"
          ><img
            src="https://static.raffolux.com/static/website/redev_images/LinkedIn+-+Negative.svg"
            alt=""
            class="imageFitStyle"
        /></a>
      </div>
      <p
        class="mobCustomFooterTxt mb-0 pb-0 footerDescription"
        :style="{
          fontWeight: 300,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          margin: '50px auto 0px !important',
          color:
            (footerData?.description?.css?.fontColor || '#ba0d0d') +
            ' !important',
          fontFamily:
            (themeData.heroDescription.font || 'Garibato') + ' !important',
          fontWeight:
            (themeData.heroDescription.fontWeight || '300') + ' !important',
        }"
      >
        This raffle is proudly brought to you by RaiseHero, a Raffolux company.
      </p>
      <p
        class="mobCustomFooterTxt mb-0 pb-0 footerDescription"
        :style="{
          fontWeight: 300,
          display: 'flex',
          flexDirection: 'column',
          margin: '50px 0 0 10px !important',
          color:
            (footerData?.description?.css?.fontColor || '#ba0d0d') +
            ' !important',
          textAlign: 'center',
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
          overflowWrap: 'break-word',
          fontFamily:
            (themeData.heroDescription.font || 'Garibato') + ' !important',
          fontWeight:
            (themeData.heroDescription.fontWeight || '300') + ' !important',
        }"
      >
        <!-- NOTE FIX here -->
        {{ footerContent?.description?.value }}
      </p>
      <p class="mobCustomFooterTxt mt-0 pb-0">
        {{ footerText }}
      </p>
      <div
        v-if="footerData?.logo?.is && isPaymentSuccess"
        :style="{ backgroundColor: footerData?.logo?.css?.bgColor }"
        class="mobcustomFooterRaffoluxSection"
      >
        <img
          :src="fetchImage(footerContent?.logo?.value)"
          class="customFooterRaffoluxImg imageFitStyle"
          alt="Logo"
        />
      </div>
    </div>
    <div v-if="!isRaffle && !isPaymentSuccess" class="stuicky-footer">
      <v-row
        :style="{
          backgroundColor: '#010A28',
        }"
        class="mobile-button"
      >
        <v-col cols="2"></v-col>
        <v-col cols="8" v-if="!winner?.is">
          <div
            v-if="
              getAllData?.Items?.status === 0 || getAllData?.Items?.status === 2
            "
            :style="{
              width: '100%',
              height: '62px',
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-around',
            }"
          >
            <div>
              <p class="customFooterTimeTxt">DAYS</p>
              <p class="customFooterTimeLeft">00</p>
            </div>
            <div class="customFooterHrsTxt">
              <p class="customFooterTimeTxt">HRS</p>
              <p class="customFooterTimeLeft">00</p>
            </div>
            <div class="customFooterMinsTxt">
              <p class="customFooterTimeTxt">MINS</p>
              <p class="customFooterTimeLeft">00</p>
            </div>
            <div>
              <p class="customFooterTimeTxt">SECS</p>
              <p class="customFooterTimeLeft">00</p>
            </div>
          </div>
          <div
            v-else
            :style="{
              width: '100%',
              height: '62px',
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-around',
            }"
          >
            <div>
              <p class="customFooterTimeTxt">DAYS</p>
              <p class="customFooterTimeLeft">
                {{ countdownTimers[0].days }}
              </p>
            </div>
            <div class="customFooterHrsTxt">
              <p class="customFooterTimeTxt">HRS</p>
              <p class="customFooterTimeLeft">
                {{ countdownTimers[0].hours }}
              </p>
            </div>
            <div class="customFooterMinsTxt">
              <p class="customFooterTimeTxt">MINS</p>
              <p class="customFooterTimeLeft">
                {{ countdownTimers[0].minutes }}
              </p>
            </div>
            <div>
              <p class="customFooterTimeTxt">SECS</p>
              <p class="customFooterTimeLeft">
                {{ countdownTimers[0].secs }}
              </p>
            </div>
          </div>
        </v-col>
        <v-col cols="8" v-else
          ><h3>
            This raffle has now finished, your winner was "{{
              winner?.name[0]
            }}"
          </h3></v-col
        >
        <v-col cols="2"></v-col>
      </v-row>
      <div
        class="customFooterRaffoluxSec enterNowButtonMobile mobile-button heroButton"
        :style="{
          height: '56px',
          alignItems: 'center',
        }"
        @click="go_to_rafflepage"
      >
        Enter Now
      </div>
    </div>
  </div>
</template>

<script>
import { bucketUrlForImages } from "@/api/api.js";
import "@/assets/css/customRaffleLandingPage.css";
import "@/assets/css/global.css";
import "@/assets/css/toggle.css";
import moment from "moment";
import { mapGetters } from "vuex";
import countDown from "./countDown.vue";

export default {
  data: () => ({
    customraffleData: {
      footer_text: "Some text from parent",
    },
    customraffleData1: {
      Title: "Some text from parent",
    },
    countdownTimers: [
      {
        days: "00",
        hours: "00",
        minutes: "00",
        secs: "00",
      },
    ],
  }),
  created() {
    this.logData();
    this.$store.dispatch("fetch_custom_raffle");
    console.log({ footerContent: this.footerContent });
    console.log({ footerData: this.footerData });
    this.startCountdown();
  },
  props: {
    isRaffle: Boolean,
    isRafflePage: Boolean,
    isPaymentSuccess: Boolean,
    isPaymentFailure: Boolean,
    contentID: {
      type: String,
      required: true,
    },
    footerTitle: {
      type: String,
      required: true,
    },
    footerText: {
      type: String,
      required: true,
    },
    assetSource: {
      type: String,
      required: true,
    },
    footerData: {
      type: Object,
      required: true,
    },
    winner: {
      type: Object,
      required: true,
    },
    themeData: {
      type: Object,
      required: true,
    },
    footerContent: {
      type: Object,
      required: true,
    },
    countDownTime: String,
  },
  mounted() {},
  components: { countDown },
  computed: {
    ...mapGetters(["getAllData"]),
    hasSocialLinks() {
      const socials = this.footerContent.socials?.links;
      return (
        socials?.facebook?.link ||
        socials?.instagram?.link ||
        socials?.twitter?.link ||
        socials?.linkedin?.link
      );
    },
  },
  methods: {
    getSocialMediaIcon(platform) {
      const icons = {
        facebook:
          "https://static.raffolux.com/static/website/redev_images/Facebook+-+Negative.svg",
        instagram:
          "https://static.raffolux.com/static/website/redev_images/Instagram+-+Negative.svg",
        twitter:
          "https://static.raffolux.com/static/website/redev_images/Twitter+-+Negative.svg",
        linkedin:
          "https://static.raffolux.com/static/website/redev_images/LinkedIn+-+Negative.svg",
      };
      return icons[platform] || "";
    },
    startCountdown() {
      setInterval(() => {
        const inputDateStr = this.countDownTime;

        if (inputDateStr) {
          // Parse and format the input date as the target time for the countdown
          const parsedDate = new Date(
            inputDateStr.replace(/(\d+)(st|nd|rd|th)/, "$1")
          );

          // Convert parsed date to a Moment object in local timezone
          const endDate = moment(parsedDate);

          // Get the current local time in the user's timezone
          const now = moment();

          // Calculate the duration remaining between now and the target end date
          const duration = moment.duration(endDate.diff(now));

          // If the countdown has reached or passed the target date, clear the interval
          if (duration.asMilliseconds() <= 0) {
            clearInterval(this.intervalId); // Save intervalId in `mounted` if necessary
            this.countdownTimers = [
              { days: "00", hours: "00", minutes: "00", secs: "00" },
            ];
            return;
          }

          // Calculate days, hours, minutes, and seconds for the countdown
          const totalDays = duration.asDays();
          const days = String(Math.floor(totalDays)).padStart(2, "0");
          const hours = String(duration.hours()).padStart(2, "0");
          const minutes = String(duration.minutes()).padStart(2, "0");
          const seconds = String(duration.seconds()).padStart(2, "0");

          // Update the countdown timers
          this.countdownTimers = [
            {
              days: days,
              hours: hours,
              minutes: minutes,
              secs: seconds,
            },
          ];
        }
      }, 1000);
    },
    logData() {
      console.log({ Pill: this.footerData });
    },
    go_to_rafflepage() {
      this.$router.push("/raffle");
    },
    checkUrl() {
      console.log({ href123: window.location.href });
      return window.location.href.includes("/raffle");
    },
    fetchImage(filenames) {
      console.log({ Pill: this.footerContent });
      return `${bucketUrlForImages}/${this.contentID}/${filenames}`;
    },
    navigateToUrl(path) {
      if (path !== "") {
        window.open(path, "_blank");
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 0px) and (max-width: 767.98px) {
  .mobile-button {
    display: flex;
  }
  .mobcustomFooterRaffoluxSec {
    margin-top: -100px;
    margin-bottom: 100px;
  }
  .mobcustomFooterRaffoluxSection {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  }
}

@media (min-width: 768px) {
  .mobile-button {
    display: none;
  }
  .paddingBottomMob {
    padding-bottom: 20px;
  }
  .mobcustomFooterRaffoluxSec {
    display: none;
  }
}

.stuicky-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.logoContainerPadding {
  padding: 24px 0;
}

.inSupportOf {
  width: 100px;
  height: auto;
}
</style>
